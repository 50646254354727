import { Asset } from './asset';
import { Filter } from './filter';

import { abbreviatedLabelFor } from '@/utilities/label/label';

class GraphAsset {
  identifier: string;
  asset: Asset;

  constructor(asset: Asset) {
    this.asset = asset;
    this.identifier = '';
    this.createIdentifier();
  }

  createIdentifier() {
    for(let pn of this.asset.propertyNames) {
      if(this.asset.simulationResult[pn] != null) {
        if(this.identifier === '') {
          this.identifier = `{"${pn}":"${this.asset.simulationResult[pn]}"`;
        } else {
          this.identifier = `${this.identifier},"${pn}":"${this.asset.simulationResult[pn]}"`;
        }
      }
    }
    this.identifier = `${this.identifier}}`;
  }

  get filter(): Filter {
    return JSON.parse(this.identifier);
  }

  get abbreviatedLabel(): string {
    return abbreviatedLabelFor(this.filter);
  }
}

export {
  GraphAsset
};
