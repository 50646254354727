import { Asset } from './asset';
import { Filter } from './filter';
// @ts-ignore
import { abbreviatedLabelFor } from '@/utilities/label/label';
import AssetSorting from '@/utilities/asset/asset-sorting';

class LayerSet {
  identifier: string;
  layerSetAssets: Asset[];
  isVisible = false;

  constructor(identifier: string, assets: Asset[]) {
    this.identifier = identifier;
    this.layerSetAssets = assets;
  }

  get filter(): Filter {
    return JSON.parse(this.identifier);
  }

  get isLoading(): boolean {
    return this.layerSetAssets.some(asset => asset.isLoading);
  }

  get IsGeometryLayer(): boolean {
    return this.layerSetAssets.some(asset => asset.simulationResult.layer_type === 'Geometry');
  }

  get showByDefault(): boolean {
    return this.layerSetAssets.some(asset => asset.simulationResult.show_by_default === true);
  }

  get abbreviatedLabel(): string {
    if (this.IsGeometryLayer) {
      const labelValues : string[] = [];
      this.layerSetAssets.forEach(asset =>
        labelValues.push(
          asset.simulationResult.filename.substring(
            0, asset.simulationResult.filename.lastIndexOf('.')
          )
        )
      );
      return labelValues.join(' · ');
    } else {
      return abbreviatedLabelFor(this.filter);
    }

  }

  get layerType() : string {
    //this makes an assumption that all assets grouped into a layer set are of the same layer type.  Currently is is a safe assumption as the UI allows a user to view
    //mutliple layer sets at once as long as they are of different layer types.  In order to do this, layer_type must be a property that layer sets are grouped by.
    return this.layerSetAssets.length > 0 ? this.layerSetAssets[0].simulationResult.layer_type : '';
  }

  get completeLabel(): string {
    if (this.layerSetAssets.some(asset => asset.simulationResult.layer_type === 'Geometry')) {
      const labelValues : string[] = [];
      labelValues.push(this.identifier);
      labelValues.push(this.abbreviatedLabel);
      return labelValues.join(': ');
    } else {
      const labelValues : string[] = [];

      const filter = JSON.parse(this.identifier);
      const filterProperties = Object.keys(filter).sort((a, b) =>  AssetSorting.GetSortOrder(a) - AssetSorting.GetSortOrder(b));
      filterProperties.forEach(property => {
        if (property !== 'layer_type') {
          if (property === 'qualifier') {
            //for qualifiers, take each one separately and create an abbreviation for it
            filter[property].split(' · ').forEach((word: string) => labelValues.push(word));
          } else{
            //for other properties, abbreviate the label as a whole
            labelValues.push(filter[property]);
          }
        }
      });

      return labelValues.join(' · ');
    }
  }
}

export {
  LayerSet
};
