import { THEMES } from '@/constants/themes';
import { EASTER_EGG_THEME_NAME, EASTER_EGG_THEME } from '@/constants/easter-egg-theme';
import _ from 'lodash';
import projectApiService from '@/network/project';

function initialState() {
  return {
    error: null,
    toggleGroup: null,
    toggleLayers: null,
    secondaryViewerToggleLayers: null,
    cameraPosition: [],
    themes: THEMES,
    easterEggThemeName: EASTER_EGG_THEME_NAME,
    selectedColorThemeKey: 'default',
    selectedSecondaryViewerColorThemeKey: 'default',
    saveLayerState: false,
    savedLayers: {},
    windowResizeRequired: false,
    secondaryViewerSavedLayers: {},
    geometryFilesUploaded: null,
    geometryFileRemoved: null,
    geometryChangeTheme: {},
    mlStatus: 'starting simulation',
    abortLoadFlag: false,
    loadingMultipleScreenshots: false,
    dataProbeStatus: false,
    graphProbeStatus: false
  };
}

const state = {...initialState(),
  //list state items here that shouldn't be reset by the call to resetState
  graphRequiresProbes: false,
  secondaryViewerGraphRequiresProbes: false,
  probeGroup: null,
  secondaryViewerProbeGroup: null
};

const getters = {
  mlStatus(state) {
    return state.mlStatus;
  },
  groupToToggle(state) {
    return state.toggleGroup;
  },
  layersToToggle(state) {
    return state.toggleLayers;
  },
  secondaryViewerLayersToToggle(state) {
    return state.secondaryViewerToggleLayers;
  },
  themeLabels(state) {
    return Object.keys(state.themes);
  },
  selectedColorTheme(state) {
    return state.themes[state.selectedColorThemeKey];
  },
  selectedSecondaryViewerColorTheme(state) {
    return state.themes[state.selectedSecondaryViewerColorThemeKey];
  },
  easterEggThemeIsNotActive(state) {
    return !Object.keys(state.themes).includes(EASTER_EGG_THEME_NAME);
  },
  easterEggThemeName(state) {
    return state.easterEggThemeName;
  },
  saveLayerState(state) {
    return state.saveLayerState;
  },
  windowResizeRequired(state) {
    return state.windowResizeRequired;
  },
  getCameraPosition(state) {
    return state.cameraPosition;
  },
  savedLayers(state) {
    return _.cloneDeep(state.savedLayers);  //return a clone to ensure that any changes make to the object aren't reflected in the saved copy
  },
  secondaryViewerSavedLayers(state) {
    return _.cloneDeep(state.secondaryViewerSavedLayers);
  },
  geometryFilesUploaded(state) {
    return state.geometryFilesUploaded;
  },
  geometryFileRemoved(state) {
    return state.geometryFileRemoved;
  },
  geometryChangeTheme(state) {
    return state.geometryChangeTheme;
  },
  abortLoadFlag(state) {
    return state.abortLoadFlag;
  },
  loadingMultipleScreenshots(state) {
    return state.loadingMultipleScreenshots;
  },
  graphRequiresProbes(state) {
    return state.graphRequiresProbes;
  },
  secondaryViewerGraphRequiresProbes(state) {
    return state.secondaryViewerGraphRequiresProbes;
  },
  probeGroup(state) {
    return state.probeGroup;
  },
  secondaryViewerProbeGroup(state) {
    return state.secondaryViewerProbeGroup;
  },
  dataProbeStatus(state) {
    return state.dataProbeStatus;
  },
  graphProbeStatus(state) {
    return state.graphProbeStatus;
  }
};

const actions = {
  toggleGroup({ commit }, groupId) {
    commit('setToggleGroup', groupId);
  },
  toggleLayers({ commit }, toggleLayers) {
    commit('setToggleLayers', toggleLayers);
  },
  secondaryViewerToggleLayers({ commit }, toggleLayers) {
    commit('setSecondaryViewerToggleLayers', toggleLayers);
  },
  selectTheme({ commit }, themeIndex) {
    commit('setSelectedColorThemeKey', themeIndex);
  },
  selectSecondaryViewerTheme({ commit }, themeIndex) {
    commit('setSelectedSecondaryViewerColorThemeKey', themeIndex);
  },
  selectEasterEggTheme({ commit }, isActive) {
    commit('setEasterEggTheme', isActive);
  },
  saveLayerState({ commit }, save) {
    commit('setSaveLayerState', save);
  },
  setWindowResizeRequired({ commit }, resizeRequired) {
    commit('setWindowResizeRequired', resizeRequired);
  },
  selectCameraPosition({ commit }, cameraPosition) {
    commit('setCameraPosition', cameraPosition);
  },
  saveLayers({ commit }, { projectId, studyId, simulationId, geometryLayers, nonGeometryLayers, presentationPlanes }) {
    commit('setSavedLayers', { projectId, studyId, simulationId, geometryLayers, nonGeometryLayers, presentationPlanes });
  },
  saveSecondaryViewerLayers({ commit }, { projectId, studyId, simulationId, geometryLayers, nonGeometryLayers, presentationPlanes }) {
    commit('setSecondaryViewerSavedLayers', { projectId, studyId, simulationId, geometryLayers, nonGeometryLayers, presentationPlanes });
  },
  reset({ commit }) {
    commit('resetState');
  },
  geometryFileUploaded({ commit }, asset) {
    commit('addGeometryFileUploaded', asset);
  },
  geometryChangeTheme({ commit }, payload) {
    commit('geometryChangeTheme', payload);
  },
  geometryFileRemoved({ commit }, asset) {
    commit('removeGeometryFileUploaded', asset);
  },
  setGeometryFilesUploaded({ commit }, assets) {
    commit('setGeometryFilesUploaded', assets);
  },
  setMLStatus({ commit }, mlStatus) {
    commit('setMLStatus', mlStatus);
  },
  async getLatestMLAPISubmissionStatus({ commit }, { projectId, studyId, configurationId, simulationId }) {
    try {
      const response = await projectApiService.getLatestMLAPISubmissionStatus(projectId, studyId, configurationId, simulationId);
      commit('setMLStatus', response);
    } catch (error) {
      commit('setError', error);
    }
  },
  setAbortLoadFlag({ commit }, flag) {
    commit('setAbortLoadFlag', flag);
  },
  setLoadingMultipleScreenshots({ commit }, status) {
    commit('setMultipleScreenshots', status);
  },
  setGraphRequiresProbes({ commit }, value) {
    commit('setGraphRequiresProbes', value);
  },
  setSecondaryViewerGraphRequiresProbes({ commit }, value) {
    commit('setSecondaryViewerGraphRequiresProbes', value);
  },
  setProbeGroup({ commit }, value) {
    commit('setProbeGroup', value);
  },
  setSecondaryViewerProbeGroup({ commit }, value) {
    commit('setSecondaryViewerProbeGroup', value);
  },
  setDataProbeStatus({ commit }, value) {
    commit('setDataProbeStatus', value);
  },
  setGraphProbeStatus({ commit }, value) {
    commit('setGraphProbeStatus', value);
  }
};

const mutations = {
  setMLStatus(state, mlStatus) {
    state.mlStatus = mlStatus;
  },
  setToggleLayers(state, toggleLayers) {
    state.toggleLayers = toggleLayers;
  },
  setSecondaryViewerToggleLayers(state, toggleLayers) {
    state.secondaryViewerToggleLayers = toggleLayers;
  },
  setToggleGroup(state, group) {
    state.toggleGroup = group;
  },
  setSelectedColorThemeKey(state, themeIndex) {
    state.selectedColorThemeKey = getters.themeLabels(state)[themeIndex];
  },
  setSelectedSecondaryViewerColorThemeKey(state, themeIndex) {
    state.selectedSecondaryViewerColorThemeKey = getters.themeLabels(state)[themeIndex];
  },
  setEasterEggTheme(state, isActive) {
    if (isActive) {
      state.themes = {
        ...state.themes,
        [EASTER_EGG_THEME_NAME]: EASTER_EGG_THEME
      };
    } else {
      delete state.themes[EASTER_EGG_THEME_NAME];
      state.themes = {
        ...state.themes
      };
    }
  },
  setSaveLayerState(state, save) {
    state.saveLayerState = save;
  },
  setWindowResizeRequired(state, resizeRequired) {
    state.windowResizeRequired = resizeRequired;
  },
  setCameraPosition(state, cameraPosition) {
    state.cameraPosition = cameraPosition;
  },
  setSavedLayers(state, { projectId, studyId, simulationId, geometryLayers, nonGeometryLayers, presentationPlanes }) {
    const key = `project:${projectId},study:${studyId},simulation:${simulationId}`;

    state.savedLayers = {
      ...state.savedLayers,  //Spread operator needs to come first or it will override the value set for the [key] with what was previously saved
      [key]: _.cloneDeep({ geometryLayers, nonGeometryLayers, presentationPlanes })  //deep clone is required to make sure that we're storing copies of the sets of layers shown in the viewer and not references to them (which would mean changes to them are reflected in the saved values)

    };
  },
  setSecondaryViewerSavedLayers(state, { projectId, studyId, simulationId, geometryLayers, nonGeometryLayers, presentationPlanes }) {
    const key = `project:${projectId},study:${studyId},simulation:${simulationId}`;

    state.secondaryViewerSavedLayers = {
      ...state.secondaryViewerSavedLayers,  //Spread operator needs to come first or it will override the value set for the [key] with what was previously saved
      [key]: _.cloneDeep({ geometryLayers, nonGeometryLayers, presentationPlanes })  //deep clone is required to make sure that we're storing copies of the sets of layers shown in the viewer and not references to them (which would mean changes to them are reflected in the saved values)

    };
  },
  resetState(state) {
    Object.entries(initialState()).forEach(element => state[element[0]] = element[1]);
  },
  addGeometryFileUploaded(state, asset) {
    if (state.geometryFilesUploaded === null) {
      state.geometryFilesUploaded = [];
    }
    state.geometryFilesUploaded.push(asset);
  },
  geometryChangeTheme(state, payload) {
    state.geometryChangeTheme = payload;
  },
  removeGeometryFileUploaded(state, asset) {
    let geometryType = asset.geometryType;
    let filename = asset.filename;

    if (state.geometryFilesUploaded === null) {
      state.geometryFilesUploaded = [];
    }
    let toRemove = state.geometryFilesUploaded.filter(file => file.simulationResult.filename == filename && file.simulationResult.geometry_type == geometryType);
    state.geometryFilesUploaded = state.geometryFilesUploaded.filter(file => file != toRemove[0]);
    if (state.geometryFilesUploaded?.length == 0) {
      state.geometryFilesUploaded = null;
    }
    state.geometryFileRemoved = toRemove;
  },
  setGeometryFilesUploaded(state, assets) {
    state.geometryFilesUploaded = assets;
  },
  setError(state, error) {
    state.error = error;
  },
  setAbortLoadFlag(state, flag) {
    state.abortLoadFlag = flag;
  },
  setMultipleScreenshots(state, status) {
    state.loadingMultipleScreenshots = status;
  },
  setGraphRequiresProbes(state, value) {
    state.graphRequiresProbes = value;
  },
  setSecondaryViewerGraphRequiresProbes(state, value) {
    state.secondaryViewerGraphRequiresProbes = value;
  },
  setProbeGroup(state, value) {
    state.probeGroup = value;
  },
  setSecondaryViewerProbeGroup(state, value) {
    state.secondaryViewerProbeGroup = value;
  },
  setDataProbeStatus(state, value) {
    state.dataProbeStatus = value;
  },
  setGraphProbeStatus(state, value) {
    state.graphProbeStatus = value;
  }
};


export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
