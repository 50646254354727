import THEME_COLORS from './theme-colors';

export const EASTER_EGG_THEME_NAME = '1980s Computer Movie';
export const EASTER_EGG_THEME = [
  { group: 'background',                           ...THEME_COLORS.SURROUND_DARK_GREY },
  { group: 'geometry.study.existing',              ...THEME_COLORS.SURROUND_GLOW },
  { group: 'geometry.study.proposed',              ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.study.future',                ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.study',                       ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.surround.existing',           ...THEME_COLORS.SURROUND_GLOW },
  { group: 'geometry.surround.proposed',           ...THEME_COLORS.SURROUND_GLOW },
  { group: 'geometry.surround.future',             ...THEME_COLORS.SURROUND_GLOW },
  { group: 'geometry.surround',                    ...THEME_COLORS.SURROUND_GLOW },
  { group: 'geometry.landscaping.existing',        ...THEME_COLORS.SURROUND_GLOW },
  { group: 'geometry.landscaping.proposed',        ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.landscaping',                 ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.inner ground.existing',       ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.ground',                      ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.inner ground',                ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.outer ground.existing',       ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.outer ground',                ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry',                             ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.terrain.existing',            ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.terrain',                     ...THEME_COLORS.TRON_GROUND_GREY },
  { group: 'geometry.mitigation.v1',               ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.mitigation.v2',               ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.mitigation',                  ...THEME_COLORS.STUDY_GLOW },
  { group: 'geometry.presentation plane',          ...THEME_COLORS.PLANE_HOT_PINK_25 },
  { group: 'geometry.usage plane',                 ...THEME_COLORS.PLANE },
  { group: 'geometry.overlay',                     ...THEME_COLORS.LIGHT_GLOW }
];