import {
  GET_PERMISSIONS,
  GET_PROJECT_PERMISSIONS,
  PERMISSIONS_RESET,
  GET_EULA,
  SUBMIT_ACCEPTED_EULA,
  GET_USER_COMPANY,
  GET_COMPANY,
  GET_COMPANY_USERS,
  GET_COMPANY_USERS_TO_ADD,
  CREATE_COMPANY_USER,
  CREATE_COMPANY_USER_ERROR,
  DELETE_COMPANY_USER,
  DELETE_COMPANY_USER_ERROR,
  UPDATE_COMPANY_USER,
  UPDATE_COMPANY_USER_ERROR,
  GET_COMPANY_ROLES,
  GET_COMPANY_SHARE_GROUPS,
  CREATE_COMPANY_SHARE_GROUP,
  CREATE_COMPANY_SHARE_GROUP_ERROR,
  DELETE_COMPANY_SHARE_GROUP,
  DELETE_COMPANY_SHARE_GROUP_ERROR,
  UPDATE_COMPANY_SHARE_GROUP,
  UPDATE_COMPANY_SHARE_GROUP_ERROR,
  GET_COMPANY_SHARE_GROUP_USERS,
  ADD_COMPANY_SHARE_GROUP_USERS,
  ADD_COMPANY_SHARE_GROUP_USERS_ERROR,
  REMOVE_COMPANY_SHARE_GROUP_USER,
  REMOVE_COMPANY_SHARE_GROUP_USER_ERROR,
  GET_COMPANY_SUBSCRIPTIONS,
  GET_COMPANY_AVAILABLE_SUBSCRIPTIONS,
  GET_COMPANY_SUBSCRIPTION_USAGE,
  GET_COMPANY_SUBSCRIPTION_TYPE_PRICES,
  GET_SIMULATION_PRICES,
  GET_SCENARIO_COST_BREAKDOWN,
  GET_USER_PENDING_PROJECT_SHARES,
  UPDATE_USER_PROJECT_SHARE,
  GET_USER_PROJECT_SHARES,
  SET_OTP_PROJECT_SHARE_ID,
  GET_USER_PENDING_GLOBAL_NOTIFICATIONS,
  UPDATE_USER_GLOBAL_NOTIFICATIONS
} from '@/store/actions/permissions';

import permissionsApiService from '@/network/permissions';

function initialState() {
  return {
    permissions: {},
    eula: null,
    userCompany: null,
    company: null,
    companyUsers: [],
    companyUsersCount: null,
    addCompanyUsers: [],
    addCompanyUsersCount: null,
    companyUserCreated: null,
    companyUserDeleted: null,
    createCompanyUserError: null,
    deleteCompanyUserError: null,
    recentlyUpdatedCompanyUserId: null,
    failureToUpdateCompanyUserId: null,
    companyRoles: [],
    companyShareGroups: [],
    companyShareGroupCreated: null,
    createCompanyShareGroupError: null,
    companyShareGroupDeleted: null,
    deleteCompanyShareGroupError: null,
    companyShareGroupUpdated: null,
    updateCompanyShareGroupError: null,
    companyShareGroupUsers: [],
    companyShareGroupUsersAdded: false,
    addCompanyShareGroupUsersError: null,
    companyShareGroupUsersRemoved: null,
    removeCompanyShareGroupUserError: null,
    companySubscriptions: [],
    companyAvailableSubscriptions: [],
    companySubscriptionUsage: [],
    companySubscriptionUsageCount: null,
    companySubscriptionTypePrices: [],
    simulationPrices: [],
    scenarioCostBreakdown: [],
    userPendingProjectShares: [],
    updatedProjectShareId: null,
    updatedProjectShareStatus: null,
    userProjectShares: [],
    otpProjectShareId: localStorage.getItem('otpProjectShareId') || null,
    userPendingGlobalNotifications: [], 
    eulaIsAccepted: false
  };
}

const state = initialState();

const getters = {
  allowedPermissions: state => state.permissions,
  eula: state => state.eula,
  eulaIsAccepted: state => state.eulaIsAccepted,
  userCompany: state => state.userCompany,
  company: state => state.company,
  companyUsers: state => state.companyUsers,
  companyUsersCount: state => state.companyUsersCount,
  addCompanyUsers: state => state.addCompanyUsers,
  addCompanyUsersCount: state => state.addCompanyUsersCount,
  companyUserCreated: state => state.companyUserCreated,
  companyUserDeleted: state => state.companyUserDeleted,
  createCompanyUserError: state => state.createCompanyUserError,
  deleteCompanyUserError: state => state.deleteCompanyUserError,
  recentlyUpdatedCompanyUserId: state => state.recentlyUpdatedCompanyUserId,
  failureToUpdateCompanyUserId: state => state.failureToUpdateCompanyUserId,
  companyRoles: state => state.companyRoles,
  companyShareGroups: state => state.companyShareGroups,
  companyShareGroupCreated: state => state.companyShareGroupCreated,
  createCompanyShareGroupError: state => state.createCompanyShareGroupError,
  companyShareGroupDeleted: state => state.companyShareGroupDeleted,
  deleteCompanyShareGroupError: state => state.deleteCompanyShareGroupError,
  companyShareGroupUpdated: state => state.companyShareGroupUpdated,
  updateCompanyShareGroupError: state => state.updateCompanyShareGroupError,
  companyShareGroupUsers: state => state.companyShareGroupUsers,
  companyShareGroupUsersAdded: state => state.companyShareGroupUsersAdded,
  addCompanyShareGroupUsersError: state => state.addCompanyShareGroupUsersError,
  companyShareGroupUsersRemoved: state => state.companyShareGroupUsersRemoved,
  removeCompanyShareGroupUserError: state => state.removeCompanyShareGroupUserError,
  companySubscriptions: state => state.companySubscriptions,
  companyAvailableSubscriptions: state => state.companyAvailableSubscriptions,
  companySubscriptionUsage: state => state.companySubscriptionUsage,
  companySubscriptionUsageCount: state => state.companySubscriptionUsageCount,
  companySubscriptionTypePrices: state => state.companySubscriptionTypePrices,
  simulationPrices: state => state.simulationPrices,
  scenarioCostBreakdown: state => state.scenarioCostBreakdown,
  userPendingProjectShares: state => state.userPendingProjectShares,
  userProjectShares: state => state.userProjectShares,
  otpProjectShareId: state => state.otpProjectShareId,
  updatedProjectShareId: state => state.updatedProjectShareId,
  updatedProjectShareStatus: state => state.updatedProjectShareStatus,
  userPendingGlobalNotifications: state => state.userPendingGlobalNotifications
};

const actions = {
  [PERMISSIONS_RESET]: async ({ commit }) => {
    await commit(PERMISSIONS_RESET);
  },
  [GET_PERMISSIONS]: async ({ commit }, permissions) => {
    try {
      const response = await permissionsApiService.getPermissions(permissions);
      await commit(GET_PERMISSIONS, response.permissions);
    } catch (error) {
      console.error(error);
    }
  },
  [GET_PROJECT_PERMISSIONS]: async ({ commit }, {projectId, permissions}) => {
    try {
      const response = await permissionsApiService.getProjectPermissions(projectId, permissions);
      await commit(GET_PROJECT_PERMISSIONS, response.permissions);
    } catch (error) {
      console.error(error);
    }
  },
  [GET_EULA]: async ({ commit }) => {
    try {
      const response = await permissionsApiService.getEula();
      commit(GET_EULA, response);
    } catch (error) {
      console.error(error);
    }
  },
  [SUBMIT_ACCEPTED_EULA]: async ({ commit }, { eula_id }) => {
    try {
      await permissionsApiService.submitEula(eula_id);
      commit(GET_EULA, null);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_USER_COMPANY]: async ({ commit }) => {
    try {
      const userCompany = await permissionsApiService.getCompany();
      commit(GET_USER_COMPANY, userCompany);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY]: async ({ commit }, company_id) => {
    try {
      const company = await permissionsApiService.getCompanyById(company_id);
      commit(GET_COMPANY, company);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY_USERS]: async ({ commit }, page) => {
    try {
      const companyUsers = await permissionsApiService.getCompanyUsers(page);
      commit(GET_COMPANY_USERS, companyUsers);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY_USERS_TO_ADD]: async ({ commit }, page) => {
    try {
      const addCompanyUsers = await permissionsApiService.getCompanyUsers(page);
      commit(GET_COMPANY_USERS_TO_ADD, addCompanyUsers);
    } catch(error) {
      console.error(error);
    }
  },
  [CREATE_COMPANY_USER]: async ({ commit }, companyUserPayload) => {
    try {
      let companyUser = await permissionsApiService.createCompanyUser(companyUserPayload);
      commit(CREATE_COMPANY_USER, companyUser);
    } catch(error) {
      commit(CREATE_COMPANY_USER_ERROR);
      console.error(error);
    }
  },
  [DELETE_COMPANY_USER]: async ({ commit }, companyUserId) => {
    try {
      await permissionsApiService.deleteCompanyUser(companyUserId);
      commit(DELETE_COMPANY_USER, companyUserId);
    } catch(error) {
      commit(DELETE_COMPANY_USER_ERROR);
      console.error(error);
    }
  },
  [UPDATE_COMPANY_USER]: async ({ commit }, { companyUserId, ...payload }) => {
    try {
      await permissionsApiService.updateCompanyUser(companyUserId, payload);
      commit(UPDATE_COMPANY_USER, { companyUserId, payload });
    } catch(error) {
      commit(UPDATE_COMPANY_USER_ERROR, companyUserId);
      console.error(error);
    }
  },
  [GET_COMPANY_ROLES]: async ({ commit }) => {
    try {
      const companyRoles = await permissionsApiService.getCompanyRoles();
      commit(GET_COMPANY_ROLES, companyRoles);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY_SHARE_GROUPS]: async ({ commit }) => {
    try {
      const companyShareGroups = await permissionsApiService.getCompanyShareGroups();
      commit(GET_COMPANY_SHARE_GROUPS, companyShareGroups);
    } catch(error) {
      console.error(error);
    }
  },
  [CREATE_COMPANY_SHARE_GROUP]: async ({ commit }, companyShareGroupPayload) => {
    try {
      let companyShareGroup = await permissionsApiService.createCompanyShareGroup(companyShareGroupPayload);
      commit(CREATE_COMPANY_SHARE_GROUP, companyShareGroup);
    } catch(error) {
      commit(CREATE_COMPANY_SHARE_GROUP_ERROR);
      console.error(error);
    }
  },
  [DELETE_COMPANY_SHARE_GROUP]: async ({ commit }, companyShareGroupId) => {
    try {
      await permissionsApiService.deleteCompanyShareGroup(companyShareGroupId);
      commit(DELETE_COMPANY_SHARE_GROUP, companyShareGroupId);
    } catch(error) {
      commit(DELETE_COMPANY_SHARE_GROUP_ERROR);
      console.error(error);
    }
  },
  [UPDATE_COMPANY_SHARE_GROUP]: async ({ commit }, { companyShareGroupId, ...payload }) => {
    try {
      await permissionsApiService.updateCompanyShareGroup(companyShareGroupId, payload);
      commit(UPDATE_COMPANY_SHARE_GROUP, companyShareGroupId);
    } catch(error) {
      commit(UPDATE_COMPANY_SHARE_GROUP_ERROR);
      console.error(error);
    }
  },
  [GET_COMPANY_SHARE_GROUP_USERS]: async ({ commit }, companyShareGroupId) => {
    try {
      commit(GET_COMPANY_SHARE_GROUP_USERS, []);
      const companyShareGroupUsers = await permissionsApiService.getCompanyShareGroupUsers(companyShareGroupId);
      commit(GET_COMPANY_SHARE_GROUP_USERS, companyShareGroupUsers);
    } catch(error) {
      console.error(error);
    }
  },
  [ADD_COMPANY_SHARE_GROUP_USERS]: async ({ commit }, { companyShareGroupId, companyShareGroupUsers }) => {
    try {
      let companyShareGroupUserIds = companyShareGroupUsers.map(user => user.id);
      await permissionsApiService.addCompanyShareGroupUsers(companyShareGroupId, companyShareGroupUserIds);
      commit(ADD_COMPANY_SHARE_GROUP_USERS, companyShareGroupUsers);
    } catch(error) {
      commit(ADD_COMPANY_SHARE_GROUP_USERS_ERROR);
      console.error(error);
    }
  },
  [REMOVE_COMPANY_SHARE_GROUP_USER]: async({ commit }, { companyShareGroupId, companyShareGroupUserId}) => {
    try {
      await permissionsApiService.removeCompanyShareGroupUser(companyShareGroupId, companyShareGroupUserId);
      commit(REMOVE_COMPANY_SHARE_GROUP_USER, companyShareGroupUserId);
    } catch(error) {
      commit(REMOVE_COMPANY_SHARE_GROUP_USER_ERROR);
      console.error(error);
    }
  },
  [GET_COMPANY_SUBSCRIPTIONS]: async ({ commit }) => {
    try {
      let companySubscriptions = await permissionsApiService.getCompanySubscriptions();
      commit(GET_COMPANY_SUBSCRIPTIONS, companySubscriptions);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY_AVAILABLE_SUBSCRIPTIONS]: async ({ commit }) => {
    try {
      let companyAvailableSubscriptions = await permissionsApiService.getCompanyAvailableSubscriptions();
      commit(GET_COMPANY_AVAILABLE_SUBSCRIPTIONS, companyAvailableSubscriptions);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY_SUBSCRIPTION_USAGE]: async ({ commit }, page) => {
    try {
      let companySubscriptionUsage = await permissionsApiService.getCompanySubscriptionUsage(
        page.pageNum, page.orderBy);
      commit(GET_COMPANY_SUBSCRIPTION_USAGE, companySubscriptionUsage);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_COMPANY_SUBSCRIPTION_TYPE_PRICES]: async ({ commit }) => {
    try {
      let companySubscriptionTypePrices = await permissionsApiService.getCompanySubscriptionTypePrices();
      commit(GET_COMPANY_SUBSCRIPTION_TYPE_PRICES, companySubscriptionTypePrices);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_SIMULATION_PRICES]: async ({ commit }) => {
    try {
      let simulationPrices = await permissionsApiService.getSimulationPrices();
      commit(GET_SIMULATION_PRICES, simulationPrices);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_SCENARIO_COST_BREAKDOWN]: async ({ commit }, { projectId, studyId, configurationId }) => {
    try {
      let scenarioCostBreakown = await permissionsApiService.getScenarioCostBreakdown(projectId, studyId, configurationId);
      commit(GET_SCENARIO_COST_BREAKDOWN, scenarioCostBreakown);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_USER_PENDING_PROJECT_SHARES]: async ({ commit }) => {
    try {
      let userPendingProjectShares = await permissionsApiService.getUserPendingProjectShares();
      commit(GET_USER_PENDING_PROJECT_SHARES, userPendingProjectShares);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_USER_PENDING_GLOBAL_NOTIFICATIONS]: async ({ commit }) => {
    try {
      let userPendingGlobalNotifications = await permissionsApiService.getUserPendingGlobalNotifications();
      commit(GET_USER_PENDING_GLOBAL_NOTIFICATIONS, userPendingGlobalNotifications);
    } catch(error) {
      console.error(error);
    }
  },
  [GET_USER_PROJECT_SHARES]: async ({ commit }) => {
    try {
      let userProjectShares = await permissionsApiService.getUserProjectShares();
      commit(GET_USER_PROJECT_SHARES, userProjectShares);
    } catch(error) {
      console.error(error);
    }
  },
  [UPDATE_USER_PROJECT_SHARE]: async ({ commit }, { projectShareId, ...payload }) => {
    try {
      await permissionsApiService.updateUserProjectShare(projectShareId, payload);
      commit(UPDATE_USER_PROJECT_SHARE, { projectShareId, payload });
    } catch(error) {
      console.error(error);
    }
  },
  [UPDATE_USER_GLOBAL_NOTIFICATIONS]: async ({ commit }, { notificationId }) => {
    try {
      await permissionsApiService.updateUserNotication(notificationId);
      commit(UPDATE_USER_GLOBAL_NOTIFICATIONS, { notificationId });
    } catch(error) {
      console.error(error);
    }
  },
  [SET_OTP_PROJECT_SHARE_ID]: ({ commit }, { projectShareId }) => {
    commit(SET_OTP_PROJECT_SHARE_ID, { projectShareId });
  }
};

const mutations = {
  [GET_PERMISSIONS]: (state, permissions) => {
    let newPermissions = {};

    Object.entries(state.permissions).forEach(element => {
      newPermissions[element[0]] = element[1];
    });

    Object.entries(permissions).forEach(element => {
      newPermissions[element[0]] = element[1];
    });

    state.permissions = newPermissions;
  },
  [GET_PROJECT_PERMISSIONS]: (state, permissions) => {
    let newPermissions = {};

    Object.entries(state.permissions).forEach(element => {
      newPermissions[element[0]] = element[1];
    });

    Object.entries(permissions).forEach(element => {
      newPermissions[element[0]] = element[1];
    });

    state.permissions = newPermissions;
  },
  [PERMISSIONS_RESET]: state => {
    Object.entries(initialState()).forEach(element => state[element[0]] = element[1]);
  },
  [GET_EULA]: (state, eula) => {
    state.eula = eula;
    if(eula == null || eula == '')
      state.eulaIsAccepted = true;
  },
  [GET_USER_COMPANY]: (state, userCompany) => {
    state.userCompany = userCompany;
  },
  [GET_COMPANY]: (state, company) => {
    state.company = company;
  },
  [GET_COMPANY_USERS]: (state, companyUsers) => {
    state.companyUsers = companyUsers.results;
    state.companyUsersCount = companyUsers.count;
  },
  [GET_COMPANY_USERS_TO_ADD]: (state, companyUsers) => {
    state.addCompanyUsers = companyUsers.results;
    state.addCompanyUsersCount = companyUsers.count - 1;
  },
  [CREATE_COMPANY_USER]: (state, companyUser) => {
    state.createCompanyUserError = null;
    state.companyUserCreated = true;
    state.companyUsers.push(companyUser);
    setTimeout(() => {
      state.companyUserCreated = null;
    }, 3000);
  },
  [CREATE_COMPANY_USER_ERROR]: (state) => {
    state.createCompanyUserError = true;
    setTimeout(() => {
      state.createCompanyUserError = null;
    }, 5000);
  },
  [DELETE_COMPANY_USER]: (state, companyUserId) => {
    state.companyUsers = state.companyUsers.filter(user => user.id !== companyUserId);
    state.companyShareGroupUsers = state.companyShareGroupUsers.filter(user => user.id !== companyUserId);
    state.companyUserDeleted = true;
    setTimeout(() => {
      state.companyUserDeleted = null;
    }, 3000);
  },
  [DELETE_COMPANY_USER_ERROR]: (state) => {
    state.deleteCompanyUserError = true;
    setTimeout(() => {
      state.deleteCompanyUserError = null;
    }, 5000);
  },
  [UPDATE_COMPANY_USER]: (state, data) => {
    if('subscription' in data.payload){
      let previousAssigneeIndex = state.companyUsers.findIndex(user => user.subscription == data.payload.subscription && user.id !== data.companyUserId);
      if(previousAssigneeIndex !== -1) state.companyUsers[previousAssigneeIndex].subscription = null;
      let nextAssigneeIndex = state.companyUsers.findIndex(user => user.id === data.companyUserId);
      state.companyUsers[nextAssigneeIndex].subscription = data.payload.subscription;
    }
    state.recentlyUpdatedCompanyUserId = data.companyUserId;
    setTimeout(() => {
      state.recentlyUpdatedCompanyUserId = null;
    }, 3000);
  },
  [UPDATE_COMPANY_USER_ERROR]: (state, companyUserId) => {
    state.failureToUpdateCompanyUserId = companyUserId;
    setTimeout(() => {
      state.failureToUpdateCompanyUserId = null;
    }, 5000);
  },
  [GET_COMPANY_ROLES]: (state, companyRoles) => {
    state.companyRoles = companyRoles;
  },
  [GET_COMPANY_SHARE_GROUPS]: (state, companyShareGroups) => {
    state.companyShareGroups = companyShareGroups;
  },
  [CREATE_COMPANY_SHARE_GROUP]: (state, companyShareGroup) => {
    if(companyShareGroup.default_for_new_projects) {
      let oldDefaultIndex = state.companyShareGroups.findIndex(group => group.default_for_new_projects);
      state.companyShareGroups[oldDefaultIndex].default_for_new_projects = false;
    }
    state.companyShareGroupCreated = true;
    state.companyShareGroups.push(companyShareGroup);
    setTimeout(() => {
      state.companyShareGroupCreated = false;
    }, 3000);
  },
  [CREATE_COMPANY_SHARE_GROUP_ERROR]: (state) => {
    state.createCompanyShareGroupError = true;
    setTimeout(() => {
      state.createCompanyShareGroupError = null;
    }, 5000);
  },
  [DELETE_COMPANY_SHARE_GROUP]: (state, companyShareGroupId) => {
    state.companyShareGroups = state.companyShareGroups.filter(group => group.id !== companyShareGroupId);
    state.companyShareGroupDeleted = true;
    setTimeout(() => {
      state.companyShareGroupDeleted = null;
    }, 3000);
  },
  [DELETE_COMPANY_SHARE_GROUP_ERROR]: (state) => {
    state.deleteCompanyShareGroupError = true;
    setTimeout(() => {
      state.deleteCompanyShareGroupError = null;
    }, 5000);
  },
  [UPDATE_COMPANY_SHARE_GROUP]: (state, companyShareGroupId) => {
    let oldDefaultIndex = state.companyShareGroups.findIndex(group =>
      group.default_for_new_projects && group.id !== companyShareGroupId);
    state.companyShareGroups[oldDefaultIndex].default_for_new_projects = false;
    let newDefaultIndex = state.companyShareGroups.findIndex(group => group.id === companyShareGroupId);
    state.companyShareGroups[newDefaultIndex].default_for_new_projects = true;
    state.companyShareGroupUpdated = true;
    setTimeout(() => {
      state.companyShareGroupUpdated = null;
    }, 3000);
  },
  [UPDATE_COMPANY_SHARE_GROUP_ERROR]: (state) => {
    state.updateCompanyShareGroupError = true;
    setTimeout(() => {
      state.updateCompanyShareGroupError = null;
    }, 5000);
  },
  [GET_COMPANY_SHARE_GROUP_USERS]: (state, companyShareGroupUsers) => {
    state.companyShareGroupUsers = companyShareGroupUsers;
  },
  [ADD_COMPANY_SHARE_GROUP_USERS]: (state, companyShareGroupUsers) => {
    state.companyShareGroupUsers = state.companyShareGroupUsers.concat(companyShareGroupUsers);
    state.companyShareGroupUsersAdded = true;
    setTimeout(() => {
      state.companyShareGroupUsersAdded = false;
    }, 3000);
  },
  [ADD_COMPANY_SHARE_GROUP_USERS_ERROR]: (state) => {
    state.addCompanyShareGroupUsersError = true;
    setTimeout(() => {
      state.addCompanyShareGroupUsersError = null;
    }, 5000);
  },
  [REMOVE_COMPANY_SHARE_GROUP_USER]: (state, companyShareGroupUserId) => {
    state.companyShareGroupUsers = state.companyShareGroupUsers.filter(user => user.id !== companyShareGroupUserId);
    state.companyShareGroupUsersRemoved = true;
    setTimeout(() => {
      state.companyShareGroupUsersRemoved = null;
    }, 3000);
  },
  [REMOVE_COMPANY_SHARE_GROUP_USER_ERROR]: (state) => {
    state.removeCompanyShareGroupUserError = true;
    setTimeout(() => {
      state.removeCompanyShareGroupUserError = null;
    }, 5000);
  },
  [GET_COMPANY_SUBSCRIPTIONS]: (state, companySubscriptions) => {
    state.companySubscriptions = companySubscriptions;
  },
  [GET_COMPANY_AVAILABLE_SUBSCRIPTIONS]: (state, companyAvailableSubscriptions) => {
    state.companyAvailableSubscriptions = companyAvailableSubscriptions;
  },
  [GET_COMPANY_SUBSCRIPTION_USAGE]: (state, companySubscriptionUsage) => {
    state.companySubscriptionUsage = companySubscriptionUsage.results;
    state.companySubscriptionUsageCount = companySubscriptionUsage.count;
  },
  [GET_COMPANY_SUBSCRIPTION_TYPE_PRICES]: (state, companySubscriptionTypePrices) => {
    state.companySubscriptionTypePrices = companySubscriptionTypePrices;
  },
  [GET_SIMULATION_PRICES]: (state, simulationPrices) => {
    state.simulationPrices = simulationPrices;
  },
  [GET_SCENARIO_COST_BREAKDOWN]: (state, scenarioCostBreakdown) => {
    state.scenarioCostBreakdown = scenarioCostBreakdown;
  },
  [GET_USER_PENDING_PROJECT_SHARES]: (state, userPendingProjectShares) => {
    state.userPendingProjectShares = userPendingProjectShares;
  },
  [GET_USER_PROJECT_SHARES]: (state, userProjectShares) => {
    state.userProjectShares = userProjectShares;
  },
  [UPDATE_USER_PROJECT_SHARE]: (state, data) => {
    state.updatedProjectShareId = data.projectShareId;
    state.updatedProjectShareStatus = data.payload.share_status;
    setTimeout(() => {
      state.updatedProjectShareId = null;
      state.updatedProjectShareStatus = null;
      state.userPendingProjectShares = state.userPendingProjectShares.filter(share => share.id !== data.projectShareId);
    }, 500);
  },
  [GET_USER_PENDING_GLOBAL_NOTIFICATIONS]: (state, userPendingGlobalNotifications) => {
    state.userPendingGlobalNotifications = userPendingGlobalNotifications;
  },
  [UPDATE_USER_GLOBAL_NOTIFICATIONS]: (state, data) => {
    state.userPendingGlobalNotifications = state.userPendingGlobalNotifications.filter(share => share.id !== data.notificationId);
  },
  [SET_OTP_PROJECT_SHARE_ID]: (state, { projectShareId }) => {
    state.otpProjectShareId = projectShareId;
    localStorage.setItem('otpProjectShareId', projectShareId);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};