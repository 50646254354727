import { Filter, GlobalFilter } from './filter';
import { KEYS_TO_FILTER_BY } from '@/utilities/asset/asset-constants';
import PropertySetter  from '@/utilities/asset/asset-property-modifier';

class Asset {
  simulationResult: any;
  isVisible = true;
  isLoading = false;
  item: string | null = null;

  get propertyNames(): string[] {
    return Reflect
      .ownKeys(this.simulationResult)
      .filter(property => KEYS_TO_FILTER_BY.includes(property as string)) as string[];
  }

  constructor(simulationResult: any) {
    this.simulationResult = PropertySetter.append_calculated_properties(simulationResult);

    //Capitalize the first word of all string properties for a more consistent output in the UI
    Object.keys(this.simulationResult).forEach(key => {
      if (this.propertyNames.some(propertyName => propertyName === key)) {
        if (typeof this.simulationResult[key] === 'string') {
          this.simulationResult[key] = this.simulationResult[key].charAt(0).toUpperCase() + this.simulationResult[key].slice(1);
        }
      }
    });
  }

  matchesFilter(filter: GlobalFilter): boolean {
    return Object
      .entries(filter)
      .filter(([, values]) => values.length > 0)
      .every(([property, values]) => values.some(criteria => String(this.simulationResult[property]) === String(criteria)));
  }

  matchesFilterExcludingKey(filter: GlobalFilter, keyToExclude: string): boolean {
    return Object
      .entries(filter)
      .filter(([key, values]) => key !== keyToExclude && values.length > 0)
      .every(([property, values]) => values.some(criteria => String(this.simulationResult[property]) === String(criteria)));
  }

  matchesIndividualFilter(filter: Filter): boolean {
    return Object
      .entries(filter)
      .every(([property, value]) => String(this.simulationResult[property]) === String(value));
  }

}

export {
  Asset
};
